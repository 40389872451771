var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"company-panel flex w-full px-6 py-6"},[(_vm.dashboards.length < 1)?_c('div',[_c('div',{staticClass:"flex bg-orange-50 border border-orange-100 p-4 text-sm text-orange-700 w-full rounded-md"},[_vm._v("No dashboards to show.")])]):_c('div',{staticClass:"flex flex-col flex-1"},[_vm._m(0),_c('div',{staticClass:"flex flex-1 lg:hidden"},[_c('div',{staticClass:"flex w-max overflow-x-scroll"},[_c('div',{staticClass:"flex"},_vm._l((_vm.dashboards),function(dashboard){return _c('a',{key:dashboard.key,class:[
                           'flex flex-col items-center justify-center px-2 py-2 text-sm leading-5 font-medium rounded focus:outline-none transition ease-in-out duration-150 truncate',
                            _vm.isSelected(dashboard.key) ? 'text-gray-900 bg-blue-200 cursor-default' :
                            'text-gray-600 hover:text-gray-900 hover:bg-gray-50 cursor-pointer'
                            ],staticStyle:{"width":"85px","height":"65px"},on:{"click":function($event){return _vm.selectDashboard(dashboard.key)}}},[_c('span',{class:['text-xl text-center', dashboard.icon],staticStyle:{"min-width":"25px"}}),_c('span',{staticClass:"w-full truncate text-center"},[_vm._v(_vm._s(dashboard.name))])])}),0)])]),_c('div',{staticClass:"hidden lg:flex flex-grow flex-col"},[_c('nav',{staticClass:"flex-1 bg-white space-y-1"},_vm._l((_vm.dashboards),function(dashboard){return _c('a',{key:dashboard.key,class:[
                           'group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded focus:outline-none transition ease-in-out duration-150',
                            _vm.isSelected(dashboard.key) ? 'text-gray-900 bg-blue-200 cursor-default' :
                            'text-gray-600 hover:text-gray-900 hover:bg-gray-50 cursor-pointer'
                            ],on:{"click":function($event){return _vm.selectDashboard(dashboard.key)}}},[_c('span',{class:['text-xl', 'mr-4', dashboard.icon],staticStyle:{"min-width":"25px"}}),_vm._v(" "+_vm._s(dashboard.name)+" ")])}),0)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex items-center flex-shrink-0 mb-3"},[_c('div',{staticClass:"text-gray-800 text-2xl"},[_vm._v("Company")])])}]

export { render, staticRenderFns }