<template>
    <div class="company-panel flex w-full px-6 py-6">
        <div v-if="dashboards.length < 1">
            <div class="flex bg-orange-50 border border-orange-100 p-4 text-sm text-orange-700 w-full rounded-md">No dashboards to show.</div>
        </div>

        <div v-else class="flex flex-col flex-1">
            <div class="flex items-center flex-shrink-0 mb-3">
                <div class="text-gray-800 text-2xl">Company</div>
            </div>


            <!-- ON SMALLER THAN LG (<1024px) -->
            <div class="flex flex-1 lg:hidden">
                <div class="flex w-max overflow-x-scroll">
                    <div class="flex">
                        <a v-for="dashboard in dashboards"
                           :key="dashboard.key"
                           @click="selectDashboard(dashboard.key)"
                           style="width: 85px; height: 65px;"
                           :class="[
                               'flex flex-col items-center justify-center px-2 py-2 text-sm leading-5 font-medium rounded focus:outline-none transition ease-in-out duration-150 truncate',
                                isSelected(dashboard.key) ? 'text-gray-900 bg-blue-200 cursor-default' :
                                'text-gray-600 hover:text-gray-900 hover:bg-gray-50 cursor-pointer'
                                ]">
                            <span :class="['text-xl text-center', dashboard.icon]" style="min-width:25px;"/>
                            <span class="w-full truncate text-center">{{ dashboard.name }}</span>
                        </a>
                    </div>
                </div>
            </div>
            <!-- END -->

            <!-- ON LG SCREENS (>1024px) -->
            <div class="hidden lg:flex flex-grow flex-col">
                <nav class="flex-1 bg-white space-y-1">
                    <a v-for="dashboard in dashboards"
                       :key="dashboard.key"
                       @click="selectDashboard(dashboard.key)"
                       :class="[
                               'group flex items-center px-2 py-2 text-sm leading-5 font-medium rounded focus:outline-none transition ease-in-out duration-150',
                                isSelected(dashboard.key) ? 'text-gray-900 bg-blue-200 cursor-default' :
                                'text-gray-600 hover:text-gray-900 hover:bg-gray-50 cursor-pointer'
                                ]">
                        <span :class="['text-xl', 'mr-4', dashboard.icon]" style="min-width:25px;"/>
                        {{ dashboard.name }}
                    </a>
                </nav>
            </div>
            <!-- END -->
        </div>
    </div>
</template>

<script>

import {DASHBOARD_KEYS} from "@/master/constants";
import {find} from "lodash";

const DASHBOARDS = [
    {key: DASHBOARD_KEYS.COMPANY_GENERAL, name: "General", icon: "fal fa-building", default: true},
    {key: DASHBOARD_KEYS.COMPANY_ADDRESSES, name: "Addresses", icon: "fal fa-map-marked-alt", default: false},
    {key: DASHBOARD_KEYS.COMPANY_USERS, name: "Users", icon: "fal fa-users", default: false},
    {key: DASHBOARD_KEYS.COMPANY_BUSINESS_UNIT_GROUPS, name: "Business unit groups", icon: "fal fa-layer-group", default: false},
    {key: DASHBOARD_KEYS.COMPANY_BUSINESS_UNITS, name: "Business units", icon: "fal fa-sitemap", default: false},
]

export default {
    name: "Panel",
    data() {
        return {
            selectedDashboard: '',
            dashboards: DASHBOARDS
        }
    },

    methods: {
        isSelected(dashboardKey) {
            return dashboardKey === this.$store.getters['master/dashboard']
        },
        selectDashboard: function (dashboardKey) {
            this.$store.commit('master/setDashboard', dashboardKey);
            this.selectedDashboard = dashboardKey;
        },

        initializeDashboards() {
            // Get the default one from the INITIAL list above
            let selectedDashboard = find(this.dashboards, dashboard => dashboard.default);

            // If there is one already selected get that
            const globalDashboard = find(this.dashboards, d => d.key === this.$store.getters['master/dashboard']);
            if (globalDashboard) {
                selectedDashboard = globalDashboard
            }

            if (selectedDashboard) {
                this.selectDashboard(selectedDashboard.key);
            }
        },

    },

    created() {
        this.initializeDashboards();
    }
}
</script>

<style scoped>
.company-panel {
    background-color: white;
}

@media (max-width: 1023px) {
    .company-panel {
        background-color: rgba(250, 250, 250, 1);
    }
}
</style>