<template>
    <div class="flex flex-col flex-grow border-r border-gray-200 bg-white h-full overflow-y-auto text-gray-600" id="side-navigation-menu">
        <div v-if="!component" class="w-full pt-6 px-6 pb-0 lg:pb-6">
            <div class="flex bg-orange-50 border border-orange-100 p-4 text-sm text-orange-700 m-6 rounded-md">No content to show. Please contact Autralis.</div>
        </div>
        <div v-else class="flex flex-1 w-full ">
            <component :is="component"/>
        </div>
    </div>
</template>

<script>
import {forEach} from "lodash";
import SettingsPanel from "@/master/views/Home/mysettings/Panel";
import CompanyPanel from "@/master/views/Home/company/Panel";
import SuppliersPanel from "@/master/views/Home/suppliers/Panel";
import MessagesPanel from "@/master/views/Home/messages/Panel";
import NewsPanel from "@/master/views/Home/news/Panel";
import {MENU_KEYS} from "@/master/constants";

const PANELS = {
    [MENU_KEYS.SETTINGS]: {key: MENU_KEYS.SETTINGS, name: "Settings", component: SettingsPanel},
    [MENU_KEYS.NEWS]: {key: MENU_KEYS.NEWS, name: "News", component: NewsPanel},
    [MENU_KEYS.MESSAGES]: {key: MENU_KEYS.MESSAGES, name: "Messages", component: MessagesPanel},
    [MENU_KEYS.COMPANY]: {key: MENU_KEYS.COMPANY, name: "Company", component: CompanyPanel},
    [MENU_KEYS.SUPPLIERS]: {key: MENU_KEYS.SUPPLIERS, name: "Suppliers", component: SuppliersPanel},
}

let MAPPED_COMPONENTS = [];
forEach(Object.keys(PANELS), key => {
    MAPPED_COMPONENTS = [...MAPPED_COMPONENTS, PANELS[key]]
})

export default {
    name: "Panel",
    data() {
        return {
            component: null
        }
    },

    methods: {
        initializeComponent() {
            const componentKey = this.$store.getters['master/application'].subMenu.methodName;
            if (componentKey && PANELS[componentKey]) {
                this.component = PANELS[componentKey].component
            }
        }
    },

    created() {
        this.initializeComponent()
    },

    components: Object.assign({}, ...(MAPPED_COMPONENTS.map(item => ({[item.name]: item.component})))),
}
</script>

<style scoped>

</style>