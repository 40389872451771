<template>
    <div class="flex flex-col w-full">


        <div class="flex flex-col items-start justify-between flex-shrink-0 sticky top-0 bg-white px-6 py-6 flex-shrink-0">
            <div class="text-gray-800 text-2xl">Messages</div>
            <div class="w-full">
                <!-- SCREENS <640px -->
                <div class="flex sm:hidden">
                    <select id="inboxes"
                            name="inboxes"
                            @change="onInboxClick($event.target.value)"
                            class="block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-blue-500 focus:border-blue-500 sm:text-sm rounded-md">
                        <option v-for="inbox in inboxes"
                                :key="inbox.key"
                                :value="inbox.key"
                                :selected="inbox.key === inboxType">
                            {{ inbox.label }}
                        </option>
                    </select>
                    <button @click="newMessage"
                            class="primary-button-dark flex items-center justify-center ml-2">
                        <i class="fal fa-plus mr-1"/><span>New</span>
                    </button>
                </div>
                <!-- SCREENS >=640px and <1024px -->
                <div class="hidden sm:flex justify-between items-center lg:hidden">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">

                            <div v-for="inbox in inboxes"
                                 :key="inbox.key"
                                 @click="onInboxClick(inbox.key)"
                                 :class="['whitespace-nowrap flex py-4 px-1 font-medium text-sm cursor-pointer',
                                  inbox.key === inboxType ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500']"
                                 aria-current="page">
                                {{ inbox.label }}

                                <div v-if="isInbox(inbox.key) && loading" class="flex items-center ml-3">
                                    <span class="fas fa-spinner-third fa-spin text-xs"/>
                                </div>

                                <div v-else-if="isInbox(inbox.key) && !loading"
                                     class="bg-blue-100 text-blue-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                                    {{ totalNewMessages }}
                                </div>
                            </div>
                        </nav>
                    </div>
                    <button @click="newMessage"
                            class="primary-button-dark flex items-center justify-center ml-2" style="max-height: 38px;">
                        <i class="fal fa-plus mr-1"/><span>New</span>
                    </button>
                </div>

                <!-- SCREENS >=1024px -->
                <div class="hidden lg:block">
                    <div class="border-b border-gray-200">
                        <nav class="-mb-px flex space-x-8" aria-label="Tabs">

                            <div v-for="inbox in inboxes"
                                 :key="inbox.key"
                                 @click="onInboxClick(inbox.key)"
                                 :class="['whitespace-nowrap flex py-4 px-1 font-medium text-sm cursor-pointer',
                                  inbox.key === inboxType ? 'border-b-2 border-blue-500 text-blue-600' : 'text-gray-500']"
                                 aria-current="page">
                                {{ inbox.label }}

                                <div v-if="isInbox(inbox.key) && loading" class="flex items-center ml-3">
                                    <span class="fas fa-spinner-third fa-spin text-xs"/>
                                </div>

                                <div v-else-if="isInbox(inbox.key) && !loading"
                                     class="bg-blue-100 text-blue-600 hidden ml-3 py-0.5 px-2.5 rounded-full text-xs font-medium md:inline-block">
                                    {{ totalNewMessages }}
                                </div>
                            </div>
                        </nav>
                    </div>
                </div>
            </div>
        </div>


        <div class="flex flex-1 w-full">
            <autralis-loading-comp v-if="loading" size="sm"/>
            <div v-else-if="!loading && loadingError" class="flex rounded-md bg-red-50 p-4 items-center">
                <i class="fal fa-exclamation-circle text-red-400 mr-3"/>
                <h3 class="text-sm font-medium text-red-800">{{ loadingError }}</h3>
            </div>

            <div v-else class="messages flex flex-col w-full mb-6 sm:mb-0">
                <div v-if="messages.length === 0" class="text-center px-6">
                    <i class="fal fa-envelope text-gray-600 text-3xl"/>
                    <p class="mt-1 text-sm text-gray-500">No messages at the moment.</p>
                </div>

                <div class="flex flex-col">
                    <div v-for="message in messages" :key="message.key"
                         :class="['min-w-0 flex justify-between items-center flex-1 px-6 hover:bg-gray-50 cursor-pointer align-top py-2',
                         isMessageSelected(message.id) ? 'bg-gray-50' : '', message.read ? '' : 'bg-blue-50']"
                         @click="openMessage(message.id)">
                        <div class="flex flex-col w-full mr-2 truncate">
                            <div class="text-sm font-medium text-gray-900 truncate">{{ message.subject }}</div>
                            <div class="text-xs text-gray-500 truncate truncate">{{ formatDate(message.sent) }}</div>
                            <div class="text-xs text-gray-500 truncate truncate">{{ message.from }}</div>
                        </div>
                        <div class="flex items-center justify-center h-8 w-8">
                            <i :class="['text-gray-500', message.read ? 'fal fa-envelope-open' : 'fal fa-envelope']"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>


        <div class="hidden lg:flex items-center justify-end flex-shrink-0 sticky bottom-0 bg-white w-full py-3 px-6">
            <button @click="newMessage"
                    class="primary-button-dark flex items-center justify-center"
                    style="height: 38px">
                <i class="fal fa-plus mr-1"/><span>New</span>
            </button>
        </div>
    </div>
</template>

<script>
import Loading from "@/components/loading";
import {handleFetchMessages} from "@/master/views/Home/messages/handlers";
import {filter, find, head, orderBy} from "lodash";
import moment from "moment";
import {DETAIL_PANEL_KEYS, INBOX_KEYS} from "@/master/constants";

const INIT_INBOXES = [
    {label: 'Inbox', key: INBOX_KEYS.INBOX, default: true},
    {label: 'Deleted', key: INBOX_KEYS.DELETED, default: false},
]

export default {
    name: "Panel",
    data() {
        return {
            loading: false,
            loadingError: null,
            inboxes: INIT_INBOXES
        }
    },

    computed: {
        messages() {
            const unread = orderBy(filter(this.$store.getters['master/messages'], m => !m.read), ['sent'], ['desc']);
            const rest = orderBy(filter(filter(this.$store.getters['master/messages'], m => m.read)), ['sent'], ['desc'])
            return [...unread, ...rest];
        },
        totalNewMessages() {
            return this.$store.getters['master/totalNewMessages'];
        },

        inboxType() {
            return this.$store.getters['master/selectedMessagesInboxType'];
        }
    },

    methods: {
        openMessage(message_id) {
            this.$store.commit('master/setSelectedMessage', message_id)
        },

        isMessageSelected(message_id) {
            return this.$store.getters['master/selectedMessage'] === message_id
        },
        newMessage() {
            const detailPayload = {
                key: DETAIL_PANEL_KEYS.NEW_MESSAGE
            }
            this.$store.commit('master/setDetail', detailPayload);
            this.$store.commit('master/openDetail');
        },

        isInbox(key) {
            return INBOX_KEYS.INBOX === key;
        },

        formatDate(date) {
            return moment(date).format('DD/MM/YYYY, HH:mm') + ' (' + moment(date, "YYYYMMDD").fromNow() + ')';
        },

        initializeInboxType() {
            const inboxType = find(this.inboxes, t => t.default).key;
            this.$store.commit('master/setSelectedMessagesInboxType', inboxType)
        },

        onInboxClick(inboxType) {
            this.$store.commit('master/setSelectedMessage', null);
            this.$store.commit('master/setSelectedMessagesInboxType', inboxType);
            this.fetchMessages();
        },

        async fetchMessages() {
            try {
                this.loading = true;
                this.loadingError = null

                const payload = {
                    type: this.$store.getters['master/selectedMessagesInboxType']
                };

                await handleFetchMessages(payload, (response) => {
                    this.$store.commit('master/setMessages', response.messages);
                    this.$store.commit('master/setTotalNewMessages', parseInt(response.totalNewMessages));
                    // If there is only one, select it
                    if (response.messages.length === 1) {
                        const msg = head(response.messages);
                        this.$store.commit('master/setSelectedMessage', msg.id);
                    }
                }, (error) => {
                    this.loadingError = error
                })

                this.loading = false;

            } catch (err) {
                this.loadingError = 'The messages could not be fetched. Please try again.'
                this.loading = false;
            }
        }
    },

    created() {
        this.initializeInboxType();
        this.fetchMessages();
    },

    components: {
        'autralis-loading-comp': Loading
    }
}
</script>

<style scoped>
@media (max-width: 1023px) {
    .messages {
        height: 204px;
        overflow-y: scroll;
    }
}

</style>